/* eslint-disable no-param-reassign */
import axios from 'axios';
import CookieHandler from './cookie-handler';
import CookieConfig from './cookie-config';

export default {
  isConsentGiven() {
    return Boolean(CookieHandler.getCookie(CookieConfig.ChoiceMade));
  },

  /**
   * Helper to update gdpr cookie level
   * @param {number} level
   * @returns {Promise} success callback
   */
  setGdprLevel(level) {
    return axios.post(CookieConfig.ApiUrl, { cookieLevel: level })
      .then(response => response.data);
  },

  getGdprLevel() {
    const level = CookieHandler.getCookie(CookieConfig.Choice);
    return level && parseInt(level, 10);
  },

  getGdprName() {
    const level = this.getGdprLevel();
    return CookieConfig.Levels[level];
  },

  /**
   * Maps name of gdpr cookie to the level
   * @param {string} name
   * @returns {number} level
   */
  getGdprLevelFromName(name) {
    const level = Object.keys(CookieConfig.Levels)
      .find(level => CookieConfig.Levels[level] === name);
    return level && parseInt(level, 10);
  },
};
