// import { Cookies } from '../../foundation';
import Cookies from 'js-cookie';

const defaultOptions = { expires: 365, secure: true };

const CookieHandler = {
  getCookie(name) {
    return Cookies.getJSON(name);
  },
  setCookie(name, value, options) {
    const props = { ...defaultOptions, ...options };

    if (!name) {
      return console.error('cookie name missing');
    }

    if (!value) {
      return console.error('cookie value missing');
    }

    return Cookies.set(name, value, props);
  },
};

export default CookieHandler;
