/*
 * Responsive Bootstrap Toolkit
 * Author:    Maciej Gurban
 * License:   MIT
 * Version:   2.6.3 (2016-06-21)
 * Origin:    https://github.com/maciej-gurban/responsive-bootstrap-toolkit
 *
 * Refactored to EcmaScript and jQuery dependency removal by Rocco Janse
 *
 */
class ResponsiveBootstrapToolkit {

  constructor() {
    // determines default debouncing interval of 'changed' method
    this.internal = 300;
    this.breakpoints = null;
    this.framework = null;
    this.detectionDivs = {
      // bootstrap 4
      bootstrap: {
        xs: ResponsiveBootstrapToolkit.createDiv('d-xs-block d-sm-none'),
        sm: ResponsiveBootstrapToolkit.createDiv('d-none d-sm-block d-md-none'),
        md: ResponsiveBootstrapToolkit.createDiv('d-none d-md-block d-lg-none'),
        lg: ResponsiveBootstrapToolkit.createDiv('d-none d-lg-block d-xl-none'),
        xl: ResponsiveBootstrapToolkit.createDiv('d-none d-xl-block'),
      },
      foundation: {
        small: ResponsiveBootstrapToolkit.createDiv('device-xs show-for-small-only'),
        medium: ResponsiveBootstrapToolkit.createDiv('device-sm show-for-medium-only'),
        large: ResponsiveBootstrapToolkit.createDiv('device-md show-for-large-only'),
        xlarge: ResponsiveBootstrapToolkit.createDiv('device-lg show-for-xlarge-only'),
      },
    };

    // content ready, add toolkit placeholder div
    document.addEventListener('DOMContentLoaded', () => {
      document.body.appendChild(ResponsiveBootstrapToolkit.createDiv('responsive-toolkit'));
    });

    // default framework
    if (this.framework === null) {
      this.use('bootstrap');
    }
  }

  /**
   * Create a div element.
   * @param {String} classList Space seperated list of classes.
   * @returns {Node} Div element node.
   */
  static createDiv(classList) {
    const el = document.createElement('div');
    el.setAttribute('class', classList);
    return el;
  }

  /**
   * Checks if element node is visible.
   * @param {Node} elem DOM element.
   * @returns {Boolean} True or false.
   */
  static isVisible(elem) {
    return !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
  }

  /**
   * Appends visibility divs after DOM loaded.
   * @param {Object} breakpoints Breakpoints object.
   * @returns void.
   */
  static applyDetectionDivs(breakpoints) {
    document.addEventListener('DOMContentLoaded', () => {
      const container = document.querySelector('.responsive-toolkit');
      Object.keys(breakpoints).forEach((alias) => {
        container.appendChild(breakpoints[alias]);
      });
    });
  }

  /**
   * Returns true if current breakpoint matches passed alias
   * @param {String} str Alias.
   * @returns {Boolean} True or false.
   */
  is(str) {
    if (ResponsiveBootstrapToolkit.isAnExpression(str)) {
      return this.isMatchingExpression(str);
    }
    return this.breakpoints[str] && ResponsiveBootstrapToolkit.isVisible(this.breakpoints[str]);
  }

  /**
   * Determines whether passed string is a parsable expression.
   * @param {String} str Operator string.
   * @returns {Boolean} True or false.
   */
  static isAnExpression(str) {
    return (str.charAt(0) === '<' || str.charAt(0) === '>');
  }

  /**
   * Returns true if currently active breakpoint matches the expression.
   * @param {Array} breakpoints Array of breakpoints.
   * @returns {Boolean} True or false.
   */
  isAnyActive(breakpoints) {
    let found = false;
    for (let i = 0; i < breakpoints.length; i++) {
      if (ResponsiveBootstrapToolkit.isVisible(this.breakpoints[breakpoints[i]])) {
        found = true;
      }
    }
    return found;
  }

  /**
   * Determines which framework-specific breakpoint detection divs to use.
   * @param {String} frameworkName Name of framework.
   * @param {Array} breakpoints Array of breakpoints.
   * @returns void
   */
  use(frameworkName, breakpoints) {
    this.framework = frameworkName.toLowerCase();

    if (this.framework === 'bootstrap' || this.framework === 'foundation') {
      this.breakpoints = this.detectionDivs[this.framework];
    } else {
      this.breakpoints = breakpoints;
    }
    ResponsiveBootstrapToolkit.applyDetectionDivs(this.breakpoints);
  }

  /**
   * Returns current breakpoint alias.
   * @returns {String} Breakpoint alias name.
   */
  current() {
    let name = 'unrecognized';
    Object.keys(this.breakpoints).forEach((alias) => {
      if (this.is(alias)) {
        name = alias;
      }
    });
    return name;
  }

  /**
   * Waits specified number of miliseconds before executing a callback.
   * @param {Function} fn Callback function.
   * @param {Number} ms Milliseconds delay.
   */
  changed(fn, ms) {
    let timer;
    return () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn();
      }, ms, this.interval);
    };
  }

  /**
   * Splits the expression in into <|> [=] alias
   * @param {String} str Operator string.
   * @returns {Object} Expression object.
   */
  static splitExpression(str) {
    // used operator
    const operator = str.charAt(0);
    // include breakpoint equal to alias?
    const orEqual = (str.charAt(1) === '=');

    // index at which breakpoint name starts.
    // For:  >sm, index = 1
    // For: >=sm, index = 2
    const index = 1 + (orEqual ? 1 : 0);

    // The remaining part of the expression, after the operator, will be treated as the
    // breakpoint name to compare with
    const breakpointName = str.slice(index);

    return {
      operator,
      orEqual,
      breakpointName,
    };
  }

  /**
   * Determines whether current breakpoint matches the expression given.
   * @param {String} str Expression.
   * @returns {Boolean} True or false.
   */
  isMatchingExpression(str) {
    const expression = ResponsiveBootstrapToolkit.splitExpression(str);

    // get names of all breakpoints
    const breakpointList = Object.keys(this.breakpoints);

    // get index of sought breakpoint in the list
    const pos = breakpointList.indexOf(expression.breakpointName);

    // breakpoint found
    if (pos !== -1) {
      let start = 0;
      let end = 0;

      /**
       * Parsing viewport.is('<=md') we interate from smallest breakpoint ('xs') and end
       * at 'md' breakpoint, indicated in the expression,
       * That makes: start = 0, end = 2 (index of 'md' breakpoint)
       *
       * Parsing viewport.is('<md') we start at index 'xs' breakpoint, and end at
       * 'sm' breakpoint, one before 'md'.
       * Which makes: start = 0, end = 1
       */
      if (expression.operator === '<') {
        start = 0;
        end = expression.orEqual ? pos + 1 : pos;
      }

      /**
       * Parsing viewport.is('>=sm') we interate from breakpoint 'sm' and end at the end
       * of breakpoint list.
       * That makes: start = 1, end = undefined
       *
       * Parsing viewport.is('>sm') we start at breakpoint 'md' and end at the end of
       * breakpoint list.
       * Which makes: start = 2, end = undefined
       */
      if (expression.operator === '>') {
        start = expression.orEqual ? pos : pos + 1;
        end = undefined;
      }

      const acceptedBreakpoints = breakpointList.slice(start, end);
      return this.isAnyActive(acceptedBreakpoints);
    }
    return false;
  }
}

export default ResponsiveBootstrapToolkit;
