/**
 * Lazy Loading of images based on classname and data-attibutes.
 *
 * @module foundation-theming
 * @version 1.0.0
 * @author Rocco Janse, rocco.janse@valtech.com
 * @requires lozad, https://apoorv.pro/lozad.js/
 */

import lozad from 'lozad';

export default class LazyLoader {
  constructor(selector, topMargin) {
    this.selector = selector;
    this.topMargin = topMargin;
    this.observer = null;
  }

  initialize() {
    const options = { rootMargin: `0px 0px ${this.topMargin} 0px` };
    this.observer = lozad(this.selector, options);
  }

  observe() {
    if (this.observer) {
      this.observer.observe();
    } else {
      throw new Error('Lazy loader not initialized');
    }
  }
}
