import CookieConsent from './cookie-consent';
import CookieConfig from './cookie-config';

export default {
  props: {
    requiredCookie: {
      type: String,
      required: true,
      validator: v => Object.values(CookieConfig).includes(v),
    },
  },
  computed: {
    /**
     * Uses the cookie level from the store to determine if the required cookie is set
     * @returns {boolean} has required level
     */
    $_requiredCookieAccepted() {
      const gdpr = this.$store.getters.getGdpr;
      const gdprLevel = CookieConsent.getGdprLevelFromName(gdpr);
      const requiredLevel = CookieConsent.getGdprLevelFromName(this.requiredCookie);
      return gdprLevel >= requiredLevel;
    },
  },
  methods: {
    /**
     * Accepts the required cookie and updates the store with it
     */
    $_acceptCookie() {
      const level = CookieConsent.getGdprLevelFromName(this.requiredCookie);

      if (typeof level !== 'number') {
        console.error('requiredCookie parameter is invalid.');
        return;
      }

      CookieConsent.setGdprLevel(level)
        .then(({ Success }) => {
          if (Success === true) {
            this.$store.commit('setGdpr', this.requiredCookie);
          }
        });
    },
  },
};
