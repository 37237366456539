/**
 * Sitecore Utilities scripts.
 *
 * @module foundation-theming
 * @version 1.0.0
 * @author Rocco Janse, rocco.janse@valtech.nl
 */
const sitecoreUtils = {
  /**
   * Returns current Sitecore modus.
   * @returns {Boolean|String} False or current modus (edit, preview, debug).
   */
  getSitecoreMode: () => {
    let currentMode = false;
    const scModes = ['edit', 'preview', 'debug'];
    scModes.forEach((mode) => {
      if (document.body.classList.contains(`pagemode-${mode}`)) {
        currentMode = mode;
      }
    });
    return currentMode;
  },
};

export default sitecoreUtils;
