// polyfills
import '@babel/polyfill';
import 'mdn-polyfills/NodeList.prototype.forEach';
import objectFitImages from 'object-fit-images';

import Vue from 'vue';
import Vuex from 'vuex';
import ResponsiveBootstrapToolkit from '@valtech/responsive-bootstrap-toolkit';
import axios from 'axios';
import lodash from 'lodash';
import Swiper from 'swiper/js/swiper';
import svg4everybody from 'svg4everybody';
import Cookies from 'js-cookie';
import Glide from '@glidejs/glide';
import scUtils from './lib/sitecore-utils';
import LazyLoader from './lib/lazy-load';
import CookieHandler from './lib/cookies/cookie-handler';
import CookieConsent from './lib/cookies/cookie-consent';
import CookieConfig from './lib/cookies/cookie-config';
import requiredCookie from './lib/cookies/required-cookie.mixin';

// bootstrap dependencies
import 'popper.js';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/dropdown';

// export vars
const viewport = new ResponsiveBootstrapToolkit();
const sitecoreUtils = scUtils;

// setup lazy loader for all images with class lazy
const lazyLoader = new LazyLoader('.lazy', '300px');

document.addEventListener('DOMContentLoaded', () => {
  // enable svg
  svg4everybody();

  // polyfill for 'object-fit' css property
  objectFitImages();

  // wait for images to come into screen before loading them
  lazyLoader.initialize();
  lazyLoader.observe();
});

// library exports
export {
  sitecoreUtils,
  viewport,
  Cookies,
  CookieConfig,
  CookieHandler,
  CookieConsent,
  requiredCookie,
  Glide,
  axios,
  lazyLoader,
  lodash,
  Swiper,
  Vue,
  Vuex,
};
