export default {
  ApiUrl: '/api/cookieconsent/setcookieconsent',
  Choice: 'CookieConsentChoice',
  ChoiceMade: 'CookieConsentChoice_UserChoiceMade',
  Levels: {
    0: 'functional',
    1: 'limited',
    2: 'optimal',
  },
};
